import cookie from "react-cookies";
import * as FBEventAPI from "../apis/FBEventAPI";
import { showErrorGlobalAlert, showInfoGlobalAlert } from './globalAlertActionCreator';
import LocalStorageUtil from '../utils/LocalStorageUtil';

export const submitFBConversionEvent = (eventname, customdata, userdata, event_id, orderNu) => {
  return function (dispatch, getState) {
    return new Promise((resolve, reject) => {

      let _fbc = cookie.load('_fbc', { path: '/' });
      let _fbp = cookie.load('_fbp', { path: '/' });

      const fbclid = LocalStorageUtil.loadFbclid();
      const entryTimestamp = LocalStorageUtil.loadEntryTimestamp();
      if (!_fbc || (_fbc && _fbc.trim() === '')) {
        if (fbclid && fbclid.trim() !== '') {
          // 手动根据 fbclid 生成fbc参数
          if (entryTimestamp && entryTimestamp.trim() !== '') {
            _fbc = `fb.1.${entryTimestamp}.${fbclid}`
          } else {
            const now = new Date().getTime();
            _fbc = `fb.1.${now}.${fbclid}`
          }
        }
      }

      _fbc = _fbc ? _fbc : '';
      _fbp = _fbp ? _fbp : '';

      if (!userdata) {
        userdata = {
          "em": "",
          "ph": "",
          "fn": "",
          "ln": "",
          "ct": "",
          "st": "",
          "zp": "",
          "country": "",
          "external_id": "",
          "fbc": _fbc,
          "fbp": _fbp,
          // "lead_id": "",
          // "anon_id": "",
          // "madid": "",
          // "page_id": "",
          // "page_scoped_user_id": "",
          // "ctwa_clid": "",
          // "ig_account_id": "",
          // "ig_sid": ""
        }
      } else {
        userdata = Object.assign({}, userdata, { "fbc": _fbc, "fbp": _fbp });
      }
      

      const formatCustomData = Object.assign({}, customdata);
      // 统一处理customdata.contents中的内容
      if (formatCustomData.contents) {

        let contents = [...formatCustomData.contents];
        formatCustomData.contents = contents.map(items => {
          if (items.tag_id) {
            delete items.tag_id;
            delete items.commentList_yes;
            delete items.createtime;
            delete items.currentPrice;
            delete items.star;
            delete items.updatetime;
            delete items.originalPrice;
            delete items.taglist;
            delete items.image;
            delete items.skulist;
            delete items.categorylist;
            delete items.category_id;
            delete items.level;
            delete items.inventory;
          }
          return items;
        });
      }

      FBEventAPI.submitFBConversionEvent(eventname, formatCustomData, userdata, event_id, orderNu);
    });
  };
};