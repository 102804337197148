


import React from 'react';
import Colors from '../constants/ColorConstants';


const LoadingIcon = ({
    size = 24,
    color = Colors.brand.primary,
    viewBox = '0 0 24 24',
    ...props
}
) => (
    <svg id="Layer_1" width="127.882" height="148.009" viewBox=".001 10.031 127.882 148.009">
        <defs>
            <path id="SVGID_1_" d="M0 10.03h127.882v148.01H.002z" />
        </defs>
        <clipPath id="SVGID_2_">
            <use href="#SVGID_1_" overflow="visible" />
        </clipPath>
        <path id="circ" fill="none" stroke="#F00" stroke-width="4" stroke-miterlimit="10" d="M84.008 25.425c24.352 8.34 41.87 31.432 41.87 58.61 0 27.18-17.52 50.273-41.87 58.612" clip-path="url(#SVGID_2_)" stroke-linejoin="round" />
        <g>
            <defs>
                <path id="SVGID_3_" d="M0 10.03h127.882v148.01H.002z" />
            </defs>
            <clipPath id="SVGID_4_">
                <use href="#SVGID_3_" overflow="visible" />
            </clipPath>
            <path id="circ2" fill="none" stroke="#F00" stroke-width="4" stroke-miterlimit="10" d="M43.874 142.647c-24.36-8.33-41.87-31.42-41.868-58.61 0-27.18 17.508-50.272 41.87-58.612" clip-path="url(#SVGID_4_)" stroke-linejoin="round" />
        </g>
        <g>
            <path id="rr" fill="#F00" d="M70.233 65.217h-7.638v15.266c-.883.295-2.055.882-2.935 1.175-.59.293-1.177.293-1.763.293V65.804s0-2.35-1.177-3.23l12.04-.293c.587.88.88 1.762 1.473 2.937zm2.05 20.846c5.58-2.643 8.225-7.342 8.225-12.626 0-3.524-1.177-5.872-2.647-7.045-.292-.59-.586-1.174-.586-1.468-.293-.88-.586-1.763-.88-2.35h.587c4.114.88 7.342 3.23 8.813 7.636 1.173 3.814.586 8.22-2.058 12.04-.59.88-2.35 2.64-4.112 4.108-.293.292-.585.292-.88.587 2.35 3.814 3.522 6.166 4.992 10.275 1.467 3.816 2.35 8.514 2.35 9.69.294 2.643 1.174 2.938 1.467 2.938l-9.69.293c.297-.588 2.94-1.466 2.94-5.58 0-2.937-.59-6.164-2.057-9.984-.587-1.757-1.764-4.406-2.936-6.458-.587.295-1.178.295-2.06.588-.585-.293-.876-1.467-1.467-2.643zm-9.688-.296v2.354c.586 0 2.938-.29 4.988-.585.59 1.175.885 2.055 1.178 2.933-.877.293-1.764.293-2.64.586-1.472.298-2.645.298-3.525.298v16.15c0 .29 0 2.35 1.173 2.936l-7.34.293c1.176-.878 1.176-2.937 1.176-3.228v-20.56c1.76-.293 3.523-.587 4.99-1.175zm.882-25.837H52.614v23.49c.88 0 4.4-.292 6.75-1.17C67 79.897 70.82 74.61 70.82 68.152c0-3.817-1.177-5.874-2.645-7.048-.883-.88-2.055-1.173-2.936-1.173-.293-.002-1.47-.002-1.763-.002zm-15.27 42.868v-42.28s0-2.35-1.172-3.23l16.442-.294c1.47 0 3.23 0 4.107.295 4.113.878 7.34 3.227 8.812 7.927 1.173 3.816.586 8.514-2.057 12.333-.587.88-2.352 2.935-4.407 4.11-.292.292-.586.292-.88.59 2.35 3.815 3.522 6.166 5.286 10.57 1.47 3.815 2.35 8.806 2.642 9.978.293 2.645 1.178 2.938 1.472 3.23l-9.69.296c.293-.588 2.935-1.467 2.935-5.874 0-2.937-.586-6.458-2.056-10.277-.587-1.757-1.76-4.4-2.937-6.458-1.467.588-4.11 1.466-5.874 1.76-1.172.298-2.642.298-4.11.59-1.467.294-2.644.294-3.523.294v16.446c0 .293 0 2.344 1.47 2.93l-7.634.292c.88-.88 1.172-2.638 1.172-3.23z" />
        </g>
    </svg>
);


export default LoadingIcon;