import * as en_GB from './en_GB';
import * as en_US from './en_US';
import * as zh_CN from './zh_CN';
import * as zh_TW from './zh_TW';
import request from '../utils/Request';
let tmp = {
  'en': en_US,
  'en-US': en_US,
  'en-GB': en_GB,
  'zh': zh_CN, // Simplified Chinese
  'zh-CN': zh_CN, // Simplified Chinese
  'zh-TW': zh_TW // Traditional Chinese
};
const I18N = {
  get: function () {
    let code = I18N.getLanCode();
    if (!tmp.hasOwnProperty(code)) {
      code = code.split('-')[0]; // 'en-US' -> 'en'
    }
    return (tmp[code] && tmp[code].kv) || tmp['en-GB'].kv;
  },
  getLanCode: function () {
    let language = request('language');
    if (!language) {
      return 'ja';
    }
    let languageWithHyphen = language.replace('_', '-');
    // 'zh-cn' -> 'zh-CN' 
    let index = languageWithHyphen.indexOf('-');
    if (index < 0) {
      return languageWithHyphen;
    }
    else {
      let langCode = languageWithHyphen.slice(0, index + 1);
      let countryCode = languageWithHyphen.slice(index + 1).toUpperCase();
      return langCode + countryCode;
    }
  },
  // getExactLanCode: function () {
  //   let code = I18N.getLanCode();
  //   if (!tmp.hasOwnProperty(code)) {
  //     code = code.split('-')[0]; // 'en-US' -> 'en'
  //   }
  //   return code;
  // },
  getText: function (key, fallbackString) {
    let value = I18N.get()[key];
    return (value != null && value != undefined) ? value : (fallbackString ? fallbackString : key);
  }
};
export default I18N;
