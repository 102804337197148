import axios from 'axios';

// Creates api instance to be used accross the project
// and set appropriate default values
//
// There is a bug in which axios leaks instance headers to the global
// axios object. There is a workaround which is using a deep object in the
// instance initialization.
// https://github.com/axios/axios/issues/1664

// let baseUrl = (window.location.protocol + "//" + window.location.host).replace('www.','story.');
let baseUrl = 'https://story.rolls-royce.it.com';

const api = axios.create({
  baseURL: baseUrl,
  headers: {
    common: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  },
  withCredentials: true,
});

// Create aliases
api.create = api.post;
api.update = api.put;

// api.interceptors.response.use(function (response) {
//   return response;
// }, function (error) {
//   if (error.response && error.response.status === 401) {
//     console.error('重新登录-----')
//     cookie.remove(StoreStateConstants.AUTHENTICATION, {path: '/'});
//     cookie.remove(StoreStateConstants.CURRENT_USER, {path: '/'});
//     unsetAPIAuthorizationToken();
//     window.location.href = `${window.location.protocol}//${window.location.host}/admin/#/login`;
//     return Promise.reject(error);
//   } else {
//     return Promise.reject(error);
//   }
// });

// Sets authorization header. Only to be used in log in
export function setAPIAuthorizationToken(accessToken) {
  api.defaults.headers['token'] = `${accessToken}`;
}

// Unsets authorization header. Only to be used in log out
export function unsetAPIAuthorizationToken() {
  delete api.defaults.headers['token'];
}

// Sets base api url. Only to be used in dev when changing environment
export function setAPIBaseUrl(baseURL) {
  api.defaults.baseURL = baseURL;
}


export default api;

