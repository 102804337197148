import React, { Component } from 'react';
import BrandIcon from '../../../icons/BrandIcon';
import Brand2Icon from '../../../icons/Brand2Icon';
import RdsBackIcon from '../../../icons/RdsBackIcon';
import classNames from 'classnames';

class BrandHeader extends Component {
    constructor(props) {
        super(props);
        this.goBack = this.goBack.bind(this);
    }

    goBack() {
        const { history } = this.props;
        history && history.goBack();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.showSecondaryBrandIcon !== this.props.showSecondaryBrandIcon) {
            // this.setState({quantity: nextProps.quantity});
        }
    }

    render() {
        const {showSecondaryBrandIcon, bg, goBack} = this.props;
        return (
            <div className={classNames('tw-z-[100] tw-w-full tw-h-[60px] tw-flex tw-justify-between tw-items-center tw-fixed tw-top-0 tw-left-0 tw-bg-white', {'!tw-bg-[rgba(0,0,0,0.3)]' : bg === 'black'})}>
                <div className='tw-w-1/3 tw-h-full tw-flex tw-items-center tw-pl-[20px]'><RdsBackIcon onClick={goBack ? goBack : this.goBack} color={bg === 'black' ? 'white' : '#281432'} /></div>
                {showSecondaryBrandIcon ? <Brand2Icon className="animate__fadeIn animate__animated" size={28} color={bg === 'black' ? 'white' : '#281432'} /> : <BrandIcon className="animate__fadeIn animate__animated" size={28} color={bg === 'black' ? 'white' : '#281432'} />}
                {/* <BrandIcon size={30} color='#171a20' /> */}
                <div className='tw-w-1/3'></div>
            </div>
        );
    }
}

export default BrandHeader;
